import React, { useState } from 'react';
import ParcelFinderContainer from '../../parcel-finder/ParcelFinderContainer';
import StickyPageHeader from '../../common2/StickyPageHeader/StickyPageHeader';
import Button from '../../common2/Button/Button';
import RichLocale from '../../common2/RichLocale';
import Check from '../../../icons/check-circle-filled.svg';
import Heading4 from '../../common/Heading4';
import Select from '../../common2/Select';
import CsvFileSelector from '../../common2/CsvFileSelector';
import Column from '../../common/layout/Column';
import ColumnsLayout from '../../common/layout/ColumnsLayout';

import './CarrierClaimPaymentsPage.scss';
import CenterLayout from '../../common2/CenterLayout/CenterLayout';

const parsingErrors = {};

const separators = [
  { value: ',', label: 'Comma ( , )' },
  { value: ';', label: 'Semicolon ( ; )' },
];

function CarrierClaimPaymentsPage(props) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [separator, setSeparator] = useState(';');

  const parseCSV = (file) => {
    return;
  };

  const onFileChange = (file) => {
    setFile(file);
    setError('');
    parseCSV(file);
  };

  const deleteCsv = () => {
    setFile(null);
  };

  const onSeparatorChange = (separator) => {
    setSeparator(separator);
  };

  return (
    <ParcelFinderContainer {...props}>
      <CenterLayout>
        <StickyPageHeader title="Carrier claim payments">
          <Button intent="success" icon={Check} disabled={!file} onClick={() => {}}>
            Save
          </Button>
        </StickyPageHeader>
        <div styleName="container">
          <Column>
            <div>
              <Heading4>How to upload carrier claim payments</Heading4>
              <RichLocale values={{ exampleFileLink: '#' }}>CARRIER_CLAIM_PAYMENTS_PAGE_DESCRIPTION</RichLocale>
            </div>
            <CsvFileSelector onChange={onFileChange} error={parsingErrors[error]} file={file} onRemove={deleteCsv}>
              Upload CSV file
            </CsvFileSelector>
            <Column spacing="medium">
              <ColumnsLayout>
                <Select
                  name="separator"
                  label="Select separator"
                  value={separator}
                  options={separators}
                  onChange={onSeparatorChange}
                />
                <div />
              </ColumnsLayout>
            </Column>
          </Column>
        </div>
      </CenterLayout>
    </ParcelFinderContainer>
  );
}

export default CarrierClaimPaymentsPage;
