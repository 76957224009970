import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';

import { ServiceLevelAgreementsPage } from '../SettingsPage';
import ShopLocalizationsPage from '../ShopSettingsPage/ShopLocalizationsPage';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import * as permissions from '../../firewall/permissions';
import Error404 from '../error-pages/Error404';
import WarehousesPage from '../settings/warehouses/WarehousesPage';

const withShopLocalizationsAccess = withPermissionRedirect(permissions.SHOP_LOCALIZATIONS_PERMISSION);
const withWarehousesPermission = withPermissionRedirect(permissions.WAREHOUSES);
const withSlaPermission = withPermissionRedirect(permissions.SLA);

const ShopSettingsRoutes = () => (
  <Switch>
    <Route exact path="/settings/shop/warehouses" component={withWarehousesPermission(WarehousesPage)} />
    <Route
      exact
      path="/settings/shop/service-level-agreements"
      component={withSlaPermission(ServiceLevelAgreementsPage)}
    />
    <Route path="/settings/shop/localizations" component={withShopLocalizationsAccess(ShopLocalizationsPage)} />
    <Redirect exact from="/settings/shop" to="/settings/user-management" />
    <Route component={Error404} />
  </Switch>
);

export default withPermissionRedirect(permissions.SHOP_SETTINGS_PERMISSION)(ShopSettingsRoutes);
